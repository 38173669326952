/* global graphql */
import React from 'react';
import TemplateWrapper from '../../components/TemplateWrapper';
import HeaderExp from '../../components/header/header-experiences';
import InfoSectionExp from '../../components/InfoSection/InfoSectionExp';
import RainbowCTA from '../../components/RainbowCTA';
import {Link} from "gatsby";
import { graphql } from "gatsby";

const metadata = {
  title: "Network Providers • magic",
  description: "Each provider who shares access to the internet earns tokens simply by keeping the connection active and broadcasting Magic. When a client connects providers earn additional tokens based on usage and quality of service. In the background, Magic Agent handles security ensuring clients stay in their own lane and cannot access local or private networks.", 
}

const ExpProvidersPage = props =>
  (<TemplateWrapper location={props.location} metadata ={metadata}>
    <HeaderExp location={props.location.pathname} />
    <main className="ExperiencesPage ExpEnterprisePage ExpProvidersPage">
      <section className="Section ExperiencesPage-heading">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-6 col-md-offset-1 ExperiencesPage-heading-title">
                <h1 className="Heading Heading-d1 light">Providers</h1>
              </div>
              <div className="col-xs-14 col-md-6 ExperiencesPage-heading-cta">
                <Link to="/waitlist/" className="BtnFilled BtnFilled-large purple uppercase" onClick={()=>window.analytics.track('Clicked Waitlist CTA',{component:'expProvidersPage'})}><span>Sign up</span></Link>
                <p className="Caption Caption-C3 light uppercase">Now Available</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section ExperiencesPage-info1 ExpEnterprisePage-info1 ExpProvidersPage-info1">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-12 col-md-offset-1">
                <hr className="ExperiencesPage-heading-hr" />
              </div>
              <div className="col-xs-14 col-md-6 col-md-offset-1">
                <InfoSectionExp data={props.data.allDataJson.edges[0].node.ExpProvInfo1} />
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 ExpProvidersPage-info1-img-container">
                <img className="ExpProvidersPage-info1-img prov-img" src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/expProviders-img.png" alt="cellular agriculture"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="Section ExperiencesPage-info2 ExpProvidersPage-info2">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row">
              <div className="col-xs-14 col-md-7 col-md-offset-1">
                <hr className="ExperiencesPage-heading-hr" />
              </div>
              <div className="col-xs-14 col-md-6 col-md-offset-1">
                <InfoSectionExp data={props.data.allDataJson.edges[0].node.ExpProvInfo2} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <RainbowCTA title="What are you waiting for?"/>
    </main>
  </TemplateWrapper>);

export default ExpProvidersPage;


export const pageQuery = graphql`
  query ExpProvidersQuery {
    allDataJson {
      edges {
        node {
          ExpProvInfo1 {
            title,
            subtitle,
            paragraph1,
            paragraph2,
            paragraph3OverCall,
            callout,
            paragraph3
          }
          ExpProvInfo2 {
            title,
            paragraph1
          }
        }
      }
    }
  }
`;
